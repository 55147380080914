import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe using environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const plans = [
    {
        name: "Basic Plan",
        price: "$10",
        credits: "100 Credits",
        duration: "month",
        isPopular: false,
        priceId: 'price_1PxO48GI6vk81n8VDNbPRDUv', // Stripe price ID for Basic Plan
    },
    {
        name: "Pro Plan",
        price: "$27",
        credits: "1000 Credits",
        duration: "month",
        isPopular: true,
        priceId: 'price_1PxOY5GI6vk81n8VHEdhYfwI', // Stripe price ID for Pro Plan
    },
    {
        name: "Annual Plan",
        price: "$185",
        credits: "20,000 Credits",
        duration: "year",
        isPopular: false,
        priceId: 'price_1PxW4wGI6vk81n8VOVrsM7oY', // Stripe price ID for Annual Plan
    },
];

const SubscriptionAndPurchase = ({ user, setCredits }) => {
    const [loading, setLoading] = useState({
        basic: false,
        pro: false,
        annual: false,
    });
    const [message, setMessage] = useState('');

    // Dynamically set domain based on environment
    const domain = window.location.hostname.includes('vercel')
        ? 'https://dressify-web-version.vercel.app'
        : 'https://dressify-ai.com';

    const handleSubscription = async (priceId, planType) => {
        setLoading((prevState) => ({ ...prevState, [planType]: true }));

        try {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                lineItems: [{ price: priceId, quantity: 1 }],
                mode: 'subscription',
                successUrl: `${domain}/success`,
                cancelUrl: `${domain}/cancel`,
                customerEmail: user.email,
            });

            if (error) {
                setMessage('Error: ' + error.message);
            } else {
                setMessage('Redirecting to payment...');
            }
        } catch (err) {
            setMessage('Error: Failed to fetch');
        } finally {
            setLoading((prevState) => ({ ...prevState, [planType]: false }));
        }
    };

    return (
        <div className="min-h-screen bg-white text-gray-800 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Choose Your Plan</h2>
                    <p className="mt-4 text-xl text-gray-600">Select the perfect plan for your AI Virtual Try-On needs</p>
                </div>

                <div className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8">
                    {plans.map((plan) => (
                        <div key={plan.name} className={`border rounded-lg p-6 shadow-lg bg-white transition-all duration-300 hover:scale-105 ${plan.isPopular ? 'border-indigo-500' : 'border-gray-200'}`}>
                            <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
                            {plan.isPopular && (
                                <span className="inline-block bg-indigo-500 text-white px-2 py-1 mt-2 rounded-full text-xs font-semibold uppercase tracking-wide">Most Popular</span>
                            )}
                            <div className="mt-4 flex items-baseline text-5xl font-extrabold text-gray-900">
                                {plan.price}
                                <span className="ml-1 text-2xl font-medium text-gray-500">/{plan.duration}</span>
                            </div>
                            <p className="mt-4 text-xl text-gray-600">{plan.credits}</p>
                            <button
                                className="w-full mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
                                onClick={() => handleSubscription(plan.priceId, plan.name.toLowerCase().replace(' ', ''))}
                                disabled={loading[plan.name.toLowerCase().replace(' ', '')]}
                            >
                                {loading[plan.name.toLowerCase().replace(' ', '')] ? 'Processing...' : 'Subscribe Now'}
                            </button>
                        </div>
                    ))}
                </div>

                <div className="mt-4">
                    <button className="text-right text-gray-500" onClick={() => window.location.reload()}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionAndPurchase;
