import React from 'react';
import { Button, Badge, Card, CardHeader, CardBody, CardFooter, Heading, Text } from "@chakra-ui/react"; // Only Chakra UI

export default function PricingPage() {
    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-600 via-purple-600 to-pink-500 relative overflow-hidden">
            {/* Grid overlay */}
            <div
                className="absolute inset-0"
                style={{
                    backgroundSize: '40px 40px',
                    backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px)'
                }}
            />

            {/* Floating elements */}
            <div className="absolute top-20 left-10 w-40 h-40 bg-pink-400 rounded-2xl transform rotate-12 opacity-50 blur-xl"></div>
            <div className="absolute top-60 right-20 w-56 h-56 bg-purple-400 rounded-full transform -rotate-12 opacity-50 blur-xl"></div>
            <div className="absolute bottom-20 left-1/4 w-72 h-72 bg-blue-400 rounded-full transform rotate-45 opacity-50 blur-xl"></div>

            {/* Content wrapper */}
            <div className="relative z-10">
                {/* Header */}
                <header className="w-full py-6 px-8">
                    <div className="container mx-auto flex items-center justify-between">
                        <div className="flex-1 md:flex-none">
                            <img src="/Logo.png" alt="VirtualTryOn Logo" width={150} height={40} className="mx-auto md:mx-0" />
                        </div>

                        <div className="hidden md:flex items-center space-x-4">
                            <span className="text-white text-lg">Welcome, <span className="font-semibold">User</span></span>
                            <div className="bg-white bg-opacity-20 rounded-full px-4 py-2 text-white text-sm">
                                Credits: <span className="font-bold">0</span>
                            </div>
                            <Button className="bg-white text-purple-600 hover:bg-purple-100 transition-colors duration-300 font-semibold">
                                Buy Credits
                            </Button>
                        </div>
                    </div>
                </header>

                {/* Main Content */}
                <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
                    <div className="text-center mb-12">
                        <h1 className="text-5xl font-bold text-white mb-4">Choose Your Plan</h1>
                        <p className="text-xl text-purple-100">Unlock the full potential of AI-powered virtual try-ons</p>
                    </div>

                    {/* Pricing Cards */}
                    <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto mb-20">
                        <PricingCard
                            name="Basic"
                            price="$10"
                            period="/month"
                            credits={100}
                            isPopular={false}
                            features={["Basic virtual try-ons", "Limited style options", "Standard support"]}
                        />

                        <PricingCard
                            name="Pro"
                            price="$27"
                            period="/month"
                            credits={1000}
                            isPopular={true}
                            features={["Advanced virtual try-ons", "Unlimited style options", "Priority support", "Exclusive designs"]}
                        />

                        <PricingCard
                            name="Annual"
                            price="$185"
                            period="/year"
                            credits={20000}
                            isPopular={false}
                            features={["All Pro features", "Yearly savings", "Early access to new features", "Dedicated account manager"]}
                        />
                    </div>

                    {/* Extra Credits Section */}
                    <div className="mt-20 text-center">
                        <h2 className="text-4xl font-bold text-white mb-4">Need Extra Credits?</h2>
                        <p className="text-xl text-purple-100 mb-12">Top up your account with additional credits anytime</p>

                        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
                            <CreditPackage amount={10} credits={100} />
                            <CreditPackage amount={50} credits={550} />
                            <CreditPackage amount={100} credits={1200} />
                        </div>
                    </div>
                </main>

                {/* Footer */}
                <footer className="bg-purple-800 bg-opacity-50 py-4 px-6 mt-12">
                    <div className="max-w-7xl mx-auto flex justify-center space-x-6 text-sm text-purple-200">
                        <a href="#" className="hover:text-white transition-colors duration-300">FAQs</a>
                        <a href="#" className="hover:text-white transition-colors duration-300">Terms and Conditions</a>
                        <a href="#" className="hover:text-white transition-colors duration-300">Privacy Policy</a>
                    </div>
                </footer>
            </div>
        </div>
    );
}

function PricingCard({ name, price, period, credits, isPopular, features }) {
    return (
        <Card className={`flex flex-col transform transition-all duration-300 hover:scale-105 ${isPopular ? 'border-yellow-400 shadow-xl scale-105' : 'hover:shadow-lg'}`}>
            <CardHeader className="bg-gradient-to-br from-purple-600 to-pink-500 text-white rounded-t-lg">
                <Heading size="md">{name}</Heading>
                {isPopular && <Badge className="absolute top-4 right-4 bg-yellow-400 text-purple-900">Most Popular</Badge>}
            </CardHeader>
            <CardBody className="flex-grow p-6">
                <div className="text-4xl font-bold mb-2">{price}<span className="text-xl font-normal text-gray-500">{period}</span></div>
                <Text className="text-lg mb-4">{credits} Credits</Text>
                <ul className="space-y-2">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center">
                            <span className="text-green-500 mr-2">&#10003;</span>
                            <span>{feature}</span>
                        </li>
                    ))}
                </ul>
            </CardBody>
            <CardFooter className="bg-gray-50 rounded-b-lg">
                <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white transition-colors duration-300" size="lg">
                    Subscribe Now
                </Button>
            </CardFooter>
        </Card>
    );
}

function CreditPackage({ amount, credits }) {
    return (
        <Card className="flex flex-col transform transition-all duration-300 hover:scale-105 hover:shadow-lg">
            <CardHeader className="bg-gradient-to-br from-yellow-400 to-yellow-500 text-purple-900 rounded-t-lg">
                <Heading size="md">${amount}</Heading>
            </CardHeader>
            <CardBody className="flex-grow p-6 text-center">
                <div className="text-3xl font-bold mb-2">{credits} Credits</div>
                <Text className="text-lg mb-4">Instant top-up</Text>
            </CardBody>
            <CardFooter className="bg-gray-50 rounded-b-lg">
                <Button className="w-full bg-yellow-400 hover:bg-yellow-500 text-purple-900 transition-colors duration-300" size="lg">
                    Buy Now
                </Button>
            </CardFooter>
        </Card>
    );
}
