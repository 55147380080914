// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth"; // Added setPersistence and browserLocalPersistence
import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAeRfGv07T8QR0Pw_NXyBy4DNp3iBTl6nY",
    authDomain: "dressify-ai.firebaseapp.com",
    projectId: "dressify-ai",
    storageBucket: "dressify-ai.appspot.com",
    messagingSenderId: "930464695992",
    appId: "1:930464695992:web:81eca52ceb0a6bd21f964d",
    measurementId: "G-XPN8W5DGHC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and set local persistence (long session)
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Set persistence to keep the user logged in across browser sessions
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        console.log("Firebase Auth persistence set to local.");
    })
    .catch((error) => {
        console.error("Error setting Firebase Auth persistence:", error);
    });

export { auth, googleProvider };

// Initialize Firestore
export const db = getFirestore(app);

// Fetch user credits from Firestore
export const getUserCredits = async (uid) => {
    const userDocRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userDocRef);

    if (!userSnap.exists()) {
        // New user, set 2 free credits
        await setDoc(userDocRef, {
            credits: 2,
            extraCredits: 0,
            hasConsumedFreeCredits: false, // Track whether free credits have been consumed
        });
        return { credits: 2, extraCredits: 0 };
    } else {
        const data = userSnap.data();
        return { credits: data.credits, extraCredits: data.extraCredits, hasConsumedFreeCredits: data.hasConsumedFreeCredits };
    }
};

// Update credits in Firestore
export const updateCredits = async (uid, credits, extraCredits, hasConsumedFreeCredits = true) => {
    const userDocRef = doc(db, 'users', uid);
    await updateDoc(userDocRef, {
        credits,
        extraCredits,
        hasConsumedFreeCredits,
    });
};
