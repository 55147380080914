import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 bg-white text-center">
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                    <svg className="h-8 w-8 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M5 13l4 4L19 7" />
                    </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Payment Successful!</h2>
                <p className="text-gray-600 mb-6">Thank you for your purchase. Your account has been credited with the purchased credits.</p>
                <p className="text-sm text-gray-500 mb-6">You will receive a confirmation email shortly with your purchase details.</p>

                <button
                    className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-3 px-6 rounded-lg mb-4"
                    onClick={() => navigate("/")}
                >
                    Start Using Your Credits
                </button>
                <button
                    className="w-full bg-gray-600 hover:bg-gray-700 text-white py-3 px-6 rounded-lg"
                    onClick={() => navigate("/")}
                >
                    Go to Dashboard
                </button>
            </div>
        </div>
    );
};

export default SuccessPage;
