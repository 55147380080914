import React, { useState, useEffect } from 'react';
import * as fal from '@fal-ai/serverless-client';
import { ClipLoader } from 'react-spinners';
import { auth, googleProvider, getUserCredits, updateCredits } from './firebase';
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Cleaned up imports, removed unused Link and useNavigate
import logo from './assets/logo.png';
import SubscriptionAndPurchase from './SubscriptionAndPurchase';
import PricingPage from './PricingPage';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import Header from './Header';

function App() {
    const [humanImageFile, setHumanImageFile] = useState(null);
    const [garmentImageFile, setGarmentImageFile] = useState(null);
    const [humanImagePreview, setHumanImagePreview] = useState('');
    const [garmentImagePreview, setGarmentImagePreview] = useState('');
    const [clothType, setClothType] = useState('Upper');
    const [imageSize, setImageSize] = useState('Default');
    const [inferenceSteps, setInferenceSteps] = useState(30);
    const [guidanceScale, setGuidanceScale] = useState(2.5);
    const [seed, setSeed] = useState('random');
    const [outputImage, setOutputImage] = useState('');
    const [status, setStatus] = useState('idle');
    const [progress, setProgress] = useState('');
    const [credits, setCredits] = useState(0);
    const [extraCredits, setExtraCredits] = useState(0);
    const [user, setUser] = useState(null);
    const [showBuyCreditsPopup, setShowBuyCreditsPopup] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    fal.config({
        credentials: "d2024fd6-545e-4e01-b4d5-967bf267c57b:f0c5a676465a6a514250548ad14c0ea7",
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            setUser(result.user);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleGoogleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleFileUpload = (event, setImageFile, setImagePreview) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('No file selected or invalid file.');
            return;
        }
        setImageFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    useEffect(() => {
        if (user) {
            const fetchCredits = async () => {
                try {
                    const { credits, extraCredits } = await getUserCredits(user.uid);
                    setCredits(credits);
                    setExtraCredits(extraCredits);
                } catch (error) {
                    console.error('Error fetching credits:', error);
                }
            };
            fetchCredits();
        }
    }, [user]);

    const handleSubmit = async () => {
        if (credits <= 0 && extraCredits <= 0) {
            setShowBuyCreditsPopup(true);
            return;
        }
        setStatus('loading');
        setIsRunning(true);
        setProgress('Image processing started...');
        const formattedClothType = clothType.toLowerCase();
        const formattedSeed = seed === 'random' ? null : parseInt(seed, 10);

        try {
            const humanImageBase64 = await convertToBase64(humanImageFile);
            const garmentImageBase64 = await convertToBase64(garmentImageFile);
            const result = await fal.subscribe('fal-ai/cat-vton', {
                input: {
                    human_image_url: humanImageBase64,
                    garment_image_url: garmentImageBase64,
                    cloth_type: formattedClothType,
                    imageSize,
                    inferenceSteps,
                    guidanceScale,
                    seed: formattedSeed,
                },
                logs: true,
                onQueueUpdate: (update) => {
                    if (update.status === 'IN_PROGRESS') {
                        const progressLogs = update.logs.map((log) => log.message).join('\n');
                        setProgress(progressLogs);
                    }
                },
            });

            setOutputImage(result.image.url);
            setStatus('success');
            setProgress('Image processing completed.');

            if (credits > 0) {
                setCredits(credits - 1);
                await updateCredits(user.uid, credits - 1, extraCredits);
            } else if (extraCredits > 0) {
                setExtraCredits(extraCredits - 1);
                await updateCredits(user.uid, credits, extraCredits - 1);
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus('error');
            setProgress('An error occurred during processing.');
        } finally {
            setIsRunning(false);
        }
    };

    const imagePreviewStyle = {
        width: "150px",
        height: "200px",
        objectFit: "cover",
    };

    if (!user) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-900 to-purple-900">
                <div className="bg-white bg-opacity-10 p-8 rounded-2xl shadow-2xl backdrop-blur-md w-full max-w-md transform transition-all duration-300 ease-in-out hover:scale-105">
                    <div className="flex justify-center mb-8">
                        <img src={logo} alt="Dressify Logo" className="w-26 h-16" />
                    </div>
                    <h2 className="text-3xl font-bold text-center text-white mb-6">Welcome to Dressify-AI</h2>
                    <p className="text-center text-gray-300 mb-8">Experience the future of fashion with AI-powered styling</p>
                    <button
                        className="w-full py-3 px-4 bg-white text-indigo-900 rounded-lg font-semibold flex items-center justify-center transition-all duration-300 ease-in-out"
                        onClick={handleGoogleLogin}
                    >
                        Login with Google
                    </button>
                    <p className="text-center text-gray-400 mt-6">By logging in, you agree to our Terms of Service and Privacy Policy</p>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <div className="bg-gray-900 text-white min-h-screen relative z-10">
                <Header user={user} credits={credits} extraCredits={extraCredits} handleGoogleLogout={handleGoogleLogout} setShowBuyCreditsPopup={setShowBuyCreditsPopup} />

                <Routes>
                    <Route path="/" element={
                        <div className="flex items-center justify-center p-8 bg-opacity-90">
                            <div className="flex w-full max-w-6xl">
                                <div className="w-1/2 bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg mr-4">
                                    {/* File Uploads */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Human Image*</label>
                                        <input type="file" onChange={(e) => handleFileUpload(e, setHumanImageFile, setHumanImagePreview)} className="w-full p-2 border border-gray-600 rounded-lg bg-white text-gray-900" accept="image/*" />
                                        {humanImagePreview && (
                                            <div className="mt-4">
                                                <img src={humanImagePreview} alt="Human Image Preview" className="rounded-lg shadow-lg" style={imagePreviewStyle} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Garment Image*</label>
                                        <input type="file" onChange={(e) => handleFileUpload(e, setGarmentImageFile, setGarmentImagePreview)} className="w-full p-2 border border-gray-600 rounded-lg bg-white text-gray-900" accept="image/*" />
                                        {garmentImagePreview && (
                                            <div className="mt-4">
                                                <img src={garmentImagePreview} alt="Garment Image Preview" className="rounded-lg shadow-lg" style={imagePreviewStyle} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Cloth Type</label>
                                        <select value={clothType} onChange={(e) => setClothType(e.target.value)} className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white">
                                            <option value="Upper">Upper</option>
                                            <option value="Lower">Lower</option>
                                            <option value="Overall">Overall</option>
                                            <option value="Inner">Inner</option>
                                            <option value="Outer">Outer</option>
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Image Size</label>
                                        <select value={imageSize} onChange={(e) => setImageSize(e.target.value)} className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white">
                                            <option value="Default">Default</option>
                                            <option value="Square">Square</option>
                                            <option value="Square HD">Square HD</option>
                                            <option value="Portrait 3:4">Portrait 3:4</option>
                                            <option value="Portrait 9:16">Portrait 9:16</option>
                                            <option value="Landscape 4:3">Landscape 4:3</option>
                                            <option value="Landscape 16:9">Landscape 16:9</option>
                                            <option value="Custom">Custom</option>
                                        </select>
                                    </div>

                                    {imageSize === 'Custom' && (
                                        <div className="flex gap-2 mt-2">
                                            <input type="text" placeholder="Width" className="w-1/2 bg-white bg-opacity-20 rounded px-3 py-2" />
                                            <input type="text" placeholder="Height" className="w-1/2 bg-white bg-opacity-20 rounded px-3 py-2" />
                                        </div>
                                    )}

                                    {/* Other Controls */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Num Inference Steps</label>
                                        <input type="range" min="1" max="100" value={inferenceSteps} onChange={(e) => setInferenceSteps(Number(e.target.value))} className="w-full" />
                                        <input type="number" value={inferenceSteps} onChange={(e) => setInferenceSteps(Number(e.target.value))} className="w-16 bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Guidance Scale (CFG)</label>
                                        <input type="range" min="0" max="10" step="0.1" value={guidanceScale} onChange={(e) => setGuidanceScale(Number(e.target.value))} className="w-full" />
                                        <input type="number" value={guidanceScale} onChange={(e) => setGuidanceScale(Number(e.target.value))} className="w-16 bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Seed</label>
                                        <input type="text" value={seed} onChange={(e) => setSeed(e.target.value)} className="w-full bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    <div className="flex justify-end gap-4">
                                        <button className="bg-gray-600 hover:bg-gray-700 px-4 py-2 rounded" onClick={() => { setHumanImageFile(null); setGarmentImageFile(null); setHumanImagePreview(''); setGarmentImagePreview(''); }}>Reset</button>
                                        <button className={`bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded flex items-center ${isRunning ? 'loading' : ''}`} onClick={handleSubmit} disabled={isRunning}>
                                            {isRunning ? 'Processing...' : 'Run'}
                                        </button>
                                    </div>
                                </div>

                                <div className="w-1/2 bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-lg font-medium">Result</h3>
                                    {status === 'loading' && (
                                        <div className="mt-4 text-center">
                                            <ClipLoader color="#00bfff" size={50} />
                                            <p className="mt-2">Generating result...</p>
                                        </div>
                                    )}
                                    {outputImage && (
                                        <div className="mt-4">
                                            <img className="max-w-full rounded-lg shadow-lg" src={outputImage} alt="Result" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    } />

                    {/* Redirect Buy Credits to Pricing Page */}
                    <Route path="/pricing" element={<PricingPage user={user} />} />
                    <Route path="/success" element={<SuccessPage />} />
                    <Route path="/cancel" element={<CancelPage />} />
                </Routes>

                {showBuyCreditsPopup && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-lg text-gray-800">
                            <h2 className="text-lg font-bold mb-4">Buy Credits</h2>
                            <SubscriptionAndPurchase user={user} setCredits={setCredits} />
                            <button onClick={() => setShowBuyCreditsPopup(false)} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
