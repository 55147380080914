import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';
import { Menu, X } from 'lucide-react';

export default function Header({ user, credits, extraCredits, handleGoogleLogout, setShowBuyCreditsPopup }) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="relative z-50 w-full py-6 px-8 bg-gradient-to-r from-purple-600 to-pink-500">
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo */}
                <Link to="/">
                    <img src={logo} alt="Dressify Logo" className="w-40 h-auto" />
                </Link>

                {/* Desktop Menu */}
                <div className="hidden md:flex items-center space-x-6">
                    <span className="text-white text-lg">
                        Welcome, <span className="font-semibold">{user.displayName}</span>
                    </span>
                </div>

                {/* Desktop Buttons */}
                <div className="hidden md:flex items-center space-x-4">
                    <div className="bg-white bg-opacity-20 rounded-full px-4 py-2 text-white text-sm">
                        Credits Remaining: <span className="font-bold">{credits}</span> | Extra Credits: <span className="font-bold">{extraCredits}</span>
                    </div>
                    <button
                        className="bg-yellow-400 text-purple-900 hover:bg-yellow-300 transition-colors duration-300 font-semibold px-4 py-2 rounded"
                        onClick={() => setShowBuyCreditsPopup(true)}
                    >
                        Buy Credits
                    </button>
                    <button
                        onClick={handleGoogleLogout}
                        className="text-white hover:text-pink-200 transition-colors duration-300"
                    >
                        Logout
                    </button>
                </div>

                {/* Mobile Menu Toggle */}
                <button className="md:hidden text-white" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden absolute top-full left-0 right-0 bg-purple-700 p-4 z-50 shadow-lg">
                    <div className="flex flex-col space-y-4">
                        <span className="text-white text-lg">
                            Welcome, <span className="font-semibold">{user.displayName}</span>
                        </span>
                        <div className="bg-white bg-opacity-20 rounded-full px-4 py-2 text-white text-sm text-center">
                            Credits Remaining: <span className="font-bold">{credits}</span> | Extra Credits: <span className="font-bold">{extraCredits}</span>
                        </div>
                        <button
                            className="bg-yellow-400 text-purple-900 hover:bg-yellow-300 transition-colors duration-300 font-semibold w-full py-2 rounded"
                            onClick={() => setShowBuyCreditsPopup(true)}
                        >
                            Buy Credits
                        </button>
                        <button
                            onClick={handleGoogleLogout}
                            className="text-white hover:text-pink-200 transition-colors duration-300 w-full py-2"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </header>
    );
}
